/* global BigInt */
// react forced issue security vulnerability if SSR is used like dev server, 
import { useSDK } from '@metamask/sdk-react'
import React, { useState, useEffect, useRef } from 'react'
import { ethers } from 'ethers'
import abi from './gitarg.json'
import tabi from './gitar.json'
import './App.css';
import logo from './img/SVG/argctl.svg';
//const arg = '0xb85C685226095d20EA648C35B9CCE6C1556006A5'
const arg = process.env.REACT_APP_ARG
const tar = process.env.REACT_APP_TAR
let provider
let signer
let contract
let gitar

function App() {
  const anchor = useRef()
  const { sdk, connected, connecting, chainId } = useSDK()
  const [account, setAccount] = useState()
  const [symbol, setSymbol] = useState()
  const [decimals, setDecimals] = useState()
  const [name, setName] = useState()
  const [totalSupply, setTotalSupply] = useState()
  const [balanceOf, setBalanceOf] = useState()
  const [price, setPrice] = useState()
  const [amount, setAmount] = useState(0)
  const [cost, setCost] = useState()
  const [receipt, setReceipt] = useState()
  const [sent, setSent] = useState(false)
  const [doj, setDoj] = useState(0)
  const connect = async () => {
    try {
      const accounts = await sdk.connect()
      setAccount(accounts[0]);
    } catch(err) {
      console.warn(`failed to connect..`, err);
    }
  };
  
  const getSymbol = async () => setSymbol(await contract.symbol())
  const getDecimals = async () => setDecimals((await contract.decimals()).toString())
  const getName = async () => setName(await contract.name())
  const getTotalSupply = async () => setTotalSupply((await contract.totalSupply()).toString())
  const getBalanceOf = async () => setBalanceOf((await contract.balanceOf(account)).toString())
  const getPrice = async () => setPrice((await gitar.price()))
  const g = async () => {
    setSent(true)
    const transaction = await gitar.g(amount, { value: cost })
    await transaction.wait()
    console.log({ transaction })
    setReceipt(transaction)
    setSent(false)
 }

 const download = async () => {}
 const print = async () => {
    const printWindow = window.open('', '_blank')
    printWindow.document.write(JSON.stringify(receipt, null, 2))
    printWindow.document.close()
    printWindow.print()
 }

  /*const targ = async () => {
    const price = await gitar.price()
    console.log({ price })
     
  }*/

  
  useEffect(() => {
    //const provider = new ethers.providers.Web3Provider(window.ethereum)
    provider = new ethers.BrowserProvider(window.ethereum)
    provider.getSigner().then(signer => {
      contract = new ethers.Contract(arg, abi, signer)
      //adds array wrapper for build import
      gitar = new ethers.Contract(tar, tabi, signer)
    })
    let j = 0
    const interval = setInterval(() => {
      j++
      if (j > 3) j = 0
      setDoj(j)
      //if (doj > 2) return setDoj(0)
    }, 1243)
    return () => clearInterval(interval)
    
  }, [])
/*symbol, setSymbol] = useState()
  const [decimals, setDecimals] = useState()
  const [name, setName] = useState()
  const [totalSupply, setTotalSupply] = useState()
  const [balanceOf, setBalanceOf] = useState()
  const [price, setPrice] = useState()
  const [amount, setAmount] = useState(0)
  const [cost, setCost] = useState()
  const [receipt, setReceipt] = useState()
  const [sent, 
  */
  useEffect(() => {
    const scrollToBottom = () => {
      //if (anchor.current) {
        anchor.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest'
        })
      //}
    }
    scrollToBottom()
  }, [symbol, decimals, name, totalSupply, balanceOf, price, amount, cost, receipt, sent])
  useEffect(() => {
    //TODO - either eth conversion or BN
    setCost(BigInt(amount || 0) * BigInt(price || 0))
  }, [amount])
        //{ /* TODO - don't set amount directly, set cost too, unless useEffect works */}
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          scroll
        </p>
        <button onClick={connect}>connect</button>
        {account && <div> {account} </div>}
        <p>down</p>
        {account && <div><h2>contract</h2><button onClick={getSymbol}>symbol</button></div>}
        {symbol && <div><h3>{symbol}</h3><button onClick={getDecimals}>decimals</button></div>}
        {decimals && <div><h3>{decimals}</h3><button onClick={getName}>name</button></div>}
        {name && <div><h3>{name}</h3><button onClick={getTotalSupply}>totalSupply</button></div>}
        {totalSupply && <div><h3>{totalSupply}</h3><button onClick={getBalanceOf}>balanceOf(me)</button></div>}
        {balanceOf && <div><h3>{balanceOf}</h3><div>(decimals) float</div></div>}
        {balanceOf && <div><input placeholder={tar} style={{ disabled: true }} /> (validator offline, please use default addresss) </div>}
        {balanceOf && <div><button onClick={getPrice}> price </button></div>}
        {price && <div> NOTE: price sale allocations use receipt, address and purchase amount at buy back for price stability </div>}
        {price && <div> {price.toString()} WEI </div>}
        {price && <div> <input type='number' value={amount} onChange={e => setAmount(e.target.value)} /> </div>}
        {cost && <div><pre>{cost.toString()} WEI</pre></div>}
        {cost && <div><button onClick={g} >purchase</button></div>}
        {sent && <div><pre>wait for confirmation and receipt {'.'.repeat(doj)} </pre></div> }
        {receipt && <button onClick={print}>print</button>} 
        <div ref={anchor}>anchor</div>
      </header>
    </div>
  );
}

export default App;
